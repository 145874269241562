// Note: this is not an angularjs service or factory because we need to share this between the main app and applicant screening

// Large files are added directly into S3 and removed from our production
const s3ProductionBucket = 'https://production-assets-typist.s3.amazonaws.com';

// Accommodation page
const highContrastStickers = `${s3ProductionBucket}/accommodation/high-contrast-stickers.jpg`;
const zoomInDemo = `${s3ProductionBucket}/accommodation/zoom-in-demo.gif`;
const largeKeysKeyboard = `${s3ProductionBucket}/accommodation/large-keys-keyboard.jpg`;
const microErgoKeyboard = `${s3ProductionBucket}/accommodation/micro-ergo-keyboard.png`;
const rigidKeys = `${s3ProductionBucket}/accommodation/rigid-keys.png`;
const singleHandKeyboard = `${s3ProductionBucket}/accommodation/single-hand-keyboard.png`;
const compactKeyboard = `${s3ProductionBucket}/accommodation/compact-keyboard.jpg`;

// Auth
const typewriterJpeg = `${s3ProductionBucket}/typewriter.jpeg`;
const typewriterWebp = `${s3ProductionBucket}/typewriter.webp`;

// Finger Animated Keyboard
import fingerAnimLeftHand1 from '~Images/finger_animations/finger-anim-left-hand-1.png';
import fingerAnimLeftHand2 from '~Images/finger_animations/finger-anim-left-hand-2.png';
import fingerAnimLeftHand3 from '~Images/finger_animations/finger-anim-left-hand-3.png';
import fingerAnimLeftHand4 from '~Images/finger_animations/finger-anim-left-hand-4.png';
import fingerAnimLeftHand5 from '~Images/finger_animations/finger-anim-left-hand-5.png';
import fingerAnimLeftHandA from '~Images/finger_animations/finger-anim-left-hand-A.png';
import fingerAnimLeftHandB from '~Images/finger_animations/finger-anim-left-hand-B.png';
import fingerAnimLeftHandBACKTICK from '~Images/finger_animations/finger-anim-left-hand-BACKTICK.png';
import fingerAnimLeftHandC from '~Images/finger_animations/finger-anim-left-hand-C.png';
import fingerAnimLeftHandCAPSLOCK from '~Images/finger_animations/finger-anim-left-hand-CAPSLOCK.png';
import fingerAnimLeftHandD from '~Images/finger_animations/finger-anim-left-hand-D.png';
import fingerAnimLeftHandE from '~Images/finger_animations/finger-anim-left-hand-E.png';
import fingerAnimLeftHandF from '~Images/finger_animations/finger-anim-left-hand-F.png';
import fingerAnimLeftHandG from '~Images/finger_animations/finger-anim-left-hand-G.png';
import fingerAnimLeftHandHOME from '~Images/finger_animations/finger-anim-left-hand-HOME.png';
import fingerAnimLeftHandLEFTSHIFT from '~Images/finger_animations/finger-anim-left-hand-LEFT-SHIFT.png';
import fingerAnimLeftHandQ from '~Images/finger_animations/finger-anim-left-hand-Q.png';
import fingerAnimLeftHandR from '~Images/finger_animations/finger-anim-left-hand-R.png';
import fingerAnimLeftHandS from '~Images/finger_animations/finger-anim-left-hand-S.png';
import fingerAnimLeftHandT from '~Images/finger_animations/finger-anim-left-hand-T.png';
import fingerAnimLeftHandTAB from '~Images/finger_animations/finger-anim-left-hand-TAB.png';
import fingerAnimLeftHandV from '~Images/finger_animations/finger-anim-left-hand-V.png';
import fingerAnimLeftHandW from '~Images/finger_animations/finger-anim-left-hand-W.png';
import fingerAnimLeftHandX from '~Images/finger_animations/finger-anim-left-hand-X.png';
import fingerAnimLeftHandZ from '~Images/finger_animations/finger-anim-left-hand-Z.png';
import fingerAnimRightHandAPOSTROPHE from '~Images/finger_animations/finger-anim-right-hand-APOSTROPHE.png';
import fingerAnimRightHandBACKSLASH from '~Images/finger_animations/finger-anim-right-hand-BACKSLASH.png';
import fingerAnimRightHandBACKSPACE from '~Images/finger_animations/finger-anim-right-hand-BACKSPACE.png';
import fingerAnimRightHandCOMMA from '~Images/finger_animations/finger-anim-right-hand-COMMA.png';
import fingerAnimRightHandDASH from '~Images/finger_animations/finger-anim-right-hand-DASH.png';
import fingerAnimRightHandENTER from '~Images/finger_animations/finger-anim-right-hand-ENTER.png';
import fingerAnimRightHandEQUAL from '~Images/finger_animations/finger-anim-right-hand-EQUAL.png';
import fingerAnimRightHandH from '~Images/finger_animations/finger-anim-right-hand-H.png';
import fingerAnimRightHandHOME from '~Images/finger_animations/finger-anim-right-hand-HOME.png';
import fingerAnimRightHandI from '~Images/finger_animations/finger-anim-right-hand-I.png';
import fingerAnimRightHandJ from '~Images/finger_animations/finger-anim-right-hand-J.png';
import fingerAnimRightHandK from '~Images/finger_animations/finger-anim-right-hand-K.png';
import fingerAnimRightHandL from '~Images/finger_animations/finger-anim-right-hand-L.png';
import fingerAnimRightHandM from '~Images/finger_animations/finger-anim-right-hand-M.png';
import fingerAnimRightHandN from '~Images/finger_animations/finger-anim-right-hand-N.png';
import fingerAnimRightHandO from '~Images/finger_animations/finger-anim-right-hand-O.png';
import fingerAnimRightHandP from '~Images/finger_animations/finger-anim-right-hand-P.png';
import fingerAnimRightHandPERIOD from '~Images/finger_animations/finger-anim-right-hand-PERIOD.png';
import fingerAnimRightHandRIGHTSHIFT from '~Images/finger_animations/finger-anim-right-hand-RIGHT-SHIFT.png';
import fingerAnimRightHandRIGHTSQUAREBRACKET from '~Images/finger_animations/finger-anim-right-hand-RIGHTSQUAREBRACKET.png';
import fingerAnimRightHandSEMICOLON from '~Images/finger_animations/finger-anim-right-hand-SEMICOLON.png';
import fingerAnimRightHandSPACE from '~Images/finger_animations/finger-anim-right-hand-SPACE.png';
import fingerAnimRightHandU from '~Images/finger_animations/finger-anim-right-hand-U.png';
import fingerAnimRightHandY from '~Images/finger_animations/finger-anim-right-hand-Y.png';

// Test link videos
// MP4s are added directly into S3 and removed from our production
const setTestLinkSettings = `${s3ProductionBucket}/mp4s/set_test_link_settings.mp4`;
const testLinksPickDayWeek = `${s3ProductionBucket}/mp4s/test_links_pick_day_week.mp4`;
const copyTestLinks = `${s3ProductionBucket}/mp4s/copy_test_links.mp4`;

// Home (student)
import handsOverKeyboardZoomed from '~Images/hands-over-keyboard-zoomed.png';
import postureSittingPositionZoomed from '~Images/posture-sitting-position-zoomed.png';
import postureEyeLevelZoomed from '~Images/posture-eye-level-zoomed.png';

// Certificates
import typistCertificate from '~Images/Typist-Certificate-R3-1584x1224.png';

// Create Account Payment
import accessCard from '~Images/access-card.png';

// Profile
import badStudentProfile from '~Images/profile/bad_student_profile.png';
import goodStudentProfile from '~Images/profile/good_student_profile.png';
import kermitMeme from '~Images/profile/kermit-meme.jpeg';

// Transcription
import transcriptionModalPrint from '~Images/transcription-modal-print.png';
import logoTranscriptionPrintBw from '~Images/logo-transcription-print-bw.png';

// Account
import typistDocumentsWithIcon from '~Images/branding/typist_documents_with_icon.png';
import typistKeyboardingWithIcon from '~Images/branding/typist_keyboarding_with_icon.png';
import ehrUniversityWithIcon from '~Images/branding/ehr_university_with_icon.png';

// Skill Assessment
import typistBrandSkillAssessment from '~Images/branding/Typist_Brand_Ext_SkillsSkills_BlueSmall.png';

// A factory that provides the url paths to assets after they were compiled via webpack
// By using import/require statements to images in project directory (webpack aliases are allowed),
// webpack emits these files into the dist folders and returns the new name of the file (including the hash that was added)
// NOTE: AngularJS factory is a singleton so this gets executed only once
// Creating a factory instead of requiring it in controllers because not all states have dedicated controllers (eg. accommodation page)
// and injecting a service/factory into an inline controller in the routes is much easier and faster
const imagesMapping = new Proxy(
  {
    // Accommodation page
    highContrastStickers,
    zoomInDemo,
    largeKeysKeyboard,
    microErgoKeyboard,
    rigidKeys,
    singleHandKeyboard,
    compactKeyboard,

    // Auth
    typewriterJpeg,
    typewriterWebp,

    // Finger Animated Keyboard
    fingerAnimLeftHand1,
    fingerAnimLeftHand2,
    fingerAnimLeftHand3,
    fingerAnimLeftHand4,
    fingerAnimLeftHand5,
    fingerAnimLeftHandA,
    fingerAnimLeftHandB,
    fingerAnimLeftHandBACKTICK,
    fingerAnimLeftHandC,
    fingerAnimLeftHandCAPSLOCK,
    fingerAnimLeftHandD,
    fingerAnimLeftHandE,
    fingerAnimLeftHandF,
    fingerAnimLeftHandG,
    fingerAnimLeftHandHOME,
    fingerAnimLeftHandLEFTSHIFT,
    fingerAnimLeftHandQ,
    fingerAnimLeftHandR,
    fingerAnimLeftHandS,
    fingerAnimLeftHandT,
    fingerAnimLeftHandTAB,
    fingerAnimLeftHandV,
    fingerAnimLeftHandW,
    fingerAnimLeftHandX,
    fingerAnimLeftHandZ,
    fingerAnimRightHandAPOSTROPHE,
    fingerAnimRightHandBACKSLASH,
    fingerAnimRightHandBACKSPACE,
    fingerAnimRightHandCOMMA,
    fingerAnimRightHandDASH,
    fingerAnimRightHandENTER,
    fingerAnimRightHandEQUAL,
    fingerAnimRightHandH,
    fingerAnimRightHandHOME,
    fingerAnimRightHandI,
    fingerAnimRightHandJ,
    fingerAnimRightHandK,
    fingerAnimRightHandL,
    fingerAnimRightHandM,
    fingerAnimRightHandN,
    fingerAnimRightHandO,
    fingerAnimRightHandP,
    fingerAnimRightHandPERIOD,
    fingerAnimRightHandRIGHTSHIFT,
    fingerAnimRightHandRIGHTSQUAREBRACKET,
    fingerAnimRightHandSEMICOLON,
    fingerAnimRightHandSPACE,
    fingerAnimRightHandU,
    fingerAnimRightHandY,

    // Test link videos
    setTestLinkSettings,
    testLinksPickDayWeek,
    copyTestLinks,

    // Home (student)
    handsOverKeyboardZoomed,
    postureSittingPositionZoomed,
    postureEyeLevelZoomed,

    // Certificates
    typistCertificate,

    // Create Account Payment
    accessCard,

    // Profile
    badStudentProfile,
    goodStudentProfile,
    kermitMeme,

    // Transcription
    transcriptionModalPrint,
    logoTranscriptionPrintBw,

    // Account
    typistDocumentsWithIcon,
    typistKeyboardingWithIcon,
    ehrUniversityWithIcon,

    // Skill Assessment
    typistBrandSkillAssessment,
  },
  {
    get(obj, prop) {
      if (!(prop in obj)) {
        throw new Error(
          `The key "${prop}" does not exist in ImagesFactory. Go to ImagesFactory in AngularJS and import it as a module (read docs).`
        );
      }
      return Reflect.get(...arguments);
    },
  }
);
export default imagesMapping;
